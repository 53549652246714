<template>
  <div :class="active === 0 ? 'pb60' : ''">
    <van-tabs
      v-model="active"
      sticky
      @change="onClick"
      color="#5d544f"
      title-active-color="#fd6e05"
    >
      <van-tab v-for="(item, index) in tabs" :title="item" :key="index">
        <div class="list" v-if="lists.length > 0">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            :offset="10"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="item" v-for="(device, key) in lists" :key="key">
              <div class="flex-x-start flex-xy-center item-top">
                <img src="~/assets/images/common/sn-icon.png" alt="" />
                <span>{{ device.deviceSn }}</span>
              </div>
              <div class="flex-x-between item-foot">
                <div class="flex-x-around  foot-left">
                  <div>当月有效笔数：{{device.monthEffectiveTransactionNum || 0}}</div>
                  <div class="line"></div>
                  <div>累计有效笔数：{{device.total || 0}}</div>
                  <!-- <div>累计日去重</div>
                  <p>大于2元交易笔数</p> -->
                </div>
                <!-- <div class="item-right">{{ list.total }}笔</div> -->
              </div>
            </div>
          </van-list>
        </div>
        <van-empty description="暂无数据" v-else />
      </van-tab>
    </van-tabs>
    <div class="bottom-box" v-if="active === 0">
      <van-button round block class="submit-btn" @click="jump"
        >去提现</van-button
      >
    </div>
  </div>
</template>

<script>
import { withdrawable, withdrawabled } from "api/common";
export default {
  name: "EquipData",
  data() {
    return {
      active: 0, //整数
      tabs: ["可申请提现设备", "已获得奖励设备"],
      lists: [],
      deviceIds: [],
      total: 0, //总共的数据条数
      loading: false,
      finished: false,
      formData: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  created() {},
  mounted() {
    this.active = Number(this.$route.query.index);
    this.deviceIds = this.$route.query.deviceIds;
    this.getData();
  },
  methods: {
    getData() {
      var func;
      if (this.active === 0) {
        func = withdrawable;
      } else {
        func = withdrawabled;
      }
      let params = {
        pageNum: this.formData.pageNum,
        pageSize: this.formData.pageSize,
      }
      func(params).then((res) => {
        if (res.code === 200) {
          let rows = res.data.list; //请求返回当页的列表
          this.loading = false;
          this.total = res.data.total;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }

          // 将新数据与老数据进行合并
          this.lists = this.lists.concat(rows);

          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.lists.length >= this.total) {
            this.finished = true;
          }
        } else {
          this.$toast.fail(res.msg);
        }
        console.log(res);
      });
    },
    onLoad() {
      this.formData.pageNum++;
      this.getData();
    },
    // 点击选项卡
    onClick(name, title) {
      this.getData();
      this.loading= false
      this.finished= false
      this.formData.pageNum=1
      this.lists=[]
    },
    //点击立即提现
    jump() {
      this.$router.push("/withdrawal");
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 539px) {
  /deep/.van-sticky--fixed {
    position: fixed;
    top: 0;
    right: none;
    left: none;
    z-index: 99;
    width: 375px;
    margin: 0 auto;
  }
}
/deep/.van-tab--active:after {
  position: absolute;
  width: 29px;
  height: 5px;
  top: 37px;
  background: linear-gradient(89deg, rgba(253, 110, 5, 0.66) 10%, #fd6e05 81%);
  content: "";
  border-radius: 3px;
}
/deep/.van-tabs__line {
  display: none;
}
/deep/.van-tabs__content {
  margin: 15px;
}
.pb60 {
  padding-bottom: 60px;
}
.list {
  font-size: 14px;
  .item {
    background: #fff;
    padding: 15px;
    margin-bottom: 15px;
    .item-top {
      img {
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }
    }
    .item-foot {
      margin-top: 14px;
      .foot-left {
        div {
          font-size: 14px;
          margin-left: 10px;
          padding: 4px;
          color: grey;
        }
        .line {
          margin-top: 0.1rem;
          width: 0.026667rem;
    height: 0.4rem;
    opacity: 0.08;
    border-right: 0.026667rem solid #333333;
        }
        p {
          color: rgba(51, 51, 51, 0.8);
        }
      }
      .item-right {
        font-size: 16px;
        color: #1e2431;
      }
    }
  }
}
</style>
